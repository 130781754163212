import React from 'react'
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import { Card, Typography } from '@mui/material';
import Style from './css/ManageAppoinment.module.scss'
const ManageAppoimnet = () => {
  return (
    <Card className={Style.mainCard}>
      <Typography className={Style.textManage}>Manage Appoimnet

      </Typography>
      <div className={Style.divIcon}>
        <ArrowOutwardOutlinedIcon className={Style.icon} />

      </div>
    </Card>
  )
}

export default ManageAppoimnet