import React from 'react'
import LoginLayout from '../Layout/LoginLayout'
import SignUpLayout from '../Layout/SignUpLayout'

import Login from '../pages/Auth/Login/Login'
import LoginSIgnUp from '../pages/Auth/Login/LoginSIgnUp'
import SignUpQuestionOne from '../pages/Auth/SignUpQuestion/SignUpQuestionOne'
import SignUpQuestionTwo from '../pages/Auth/SignUpQuestion/SignUpQuestionTwo'
import SignUpQuestionThree from '../pages/Auth/SignUpQuestion/SignUpQuestionThree'
import SignUp from '../pages/Auth/SignuUp/SignUp'
import SignUpPersonalise from '../pages/Auth/SignuUp/SignUpPersonalise'
import Splash from '../pages/Splash/Splash'
import SignUpQuestionFour from '../pages/Auth/SignUpQuestion/SignUpQuestionFour'
import SignUpSomeoneForm from '../pages/Auth/SignuUp/SignUpSomeoneForm'
import SignUpQuestionNotification from '../pages/Auth/SignUpQuestion/SignUpQuestionNotification'
import SignUpPermission from '../pages/Auth/SignUpQuestion/SignUpPermission'
import SignUpQuestionsSetup from '../pages/Auth/SignUpQuestion/SignUpQuestionsSetup'
import DashBoard from '../pages/Dashboard/DashBoard'
import { Navigate } from 'react-router-dom'
import AppoinmentDashnoard from '../pages/Appoinment/AppoinmentDashboard/AppoinmentDashnoard'
import DashBoardBackup from '../pages/Dashboard/DashboardBackup'
import ManagePackage from '../pages/Appoinment/Flow1/Manage Package/ManagePackage'
import DoctorListing from '../pages/Appoinment/Flow1/Doctor Listing/DoctorListing'
const IndexRoutes = [
    {
        path: "/auth/login",
        element: LoginLayout,
        subRoutes: [
            { path: "", element: Login, exact: true },
            { path: 'signup', element: LoginSIgnUp, exact: true }
        ]
    },
    {
        path: "/auth/signup",
        element: SignUpLayout,
        subRoutes: [
            { path: "", element: SignUp, exact: true },
            { path: 'mobile', element: LoginSIgnUp, exact: true },
        ]
    },

    {
        path: "/splash",
        element: Splash,

    },
    {
        path: "/auth/signupquestion",
        element: SignUpLayout,
        subRoutes: [
            { path: "one", element: SignUpQuestionOne, exact: true },
            {
                path: 'someone/form', element: SignUpSomeoneForm, exact: true
            },
            { path: 'myself/two', element: SignUpQuestionTwo, exact: true },
            { path: 'someone/two', element: SignUpQuestionTwo, exact: true },
            { path: 'someone/three', element: SignUpQuestionThree, exact: true },
            { path: 'myself/three', element: SignUpQuestionThree, exact: true },
            { path: 'someone/four', element: SignUpQuestionFour, exact: true },
            { path: 'myself/four', element: SignUpQuestionFour, exact: true },
            { path: 'someone/five', element: SignUpQuestionNotification, exact: true },
            { path: 'myself/five', element: SignUpQuestionNotification, exact: true },
            { path: 'someone/six', element: SignUpPermission, exact: true },
            {
                path: 'myself/six', element: SignUpPermission, exact: true
            }, {
                path: 'setup', element: SignUpQuestionsSetup, exact: true
            },
        ]
    },
    {
        path: '/dashboard', element: DashBoard, exact: true
    },
    { path: '/dashboard2', element: DashBoardBackup, exact: true },

    {
        path: '/dashboard/appoinment', element: AppoinmentDashnoard, exact: true
    },
    {
        path: '/appoinment/manage', element: ManagePackage, exact: true
    }, {
        path: '/auth/signup/personalization', element: SignUpPersonalise, exact: true
    },
    {path:'/appoinment/doctorlist',element:DoctorListing,exact:true},
    { path: "*", element: () => <h1>Not found</h1> }]
export default IndexRoutes