import React from 'react';
import { Card, Typography } from '@mui/material'
import DuoIcon from '@mui/icons-material/Duo';
import Style from './css/StartAppoinmentCard.module.scss'
import { BsCameraVideo } from "react-icons/bs";

const StartAppoinmentCard = () => {
    return (
        <Card className={Style.mainCard}>
            <div style={{
                display: 'flex',
                // padding: '80px 25px'
            }}>
                <DuoIcon style={{
                    color: '#0060ff',
                    fontSize: '3.5rem'

                }} />
                <BsCameraVideo color='#fff' />
                <Typography className={Style.textStart}>
                    Start Appoimnment

                </Typography>

            </div>
        </Card>

    )
}

export default StartAppoinmentCard