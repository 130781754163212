import React, { useEffect } from 'react'
import Style from './ManagePackage.module.scss';
import Propic from '../../../../asserts/appoinment/Rectangle_102.png'
import { useState } from 'react';
import { isNotEmpty } from '../../../../helpers/objectHelper';
import { Button, Card, Typography } from '@mui/material';
const ManagePackage = () => {
    const [emptyPackage, setEmptyPackage] = useState(true);
    const [emptySession, setEmptySession] = useState(false);
    const packages = [{ name: 'package 1', session: [{ title: 'Psychiatrist Consultation', doctor: 'Dr.Keerthi Sagar', date: '27 July • 02:30 PM', image: Propic, status: 'completed' }, { title: 'Psychiatrist Consultation', doctor: 'Dr.Keerthi Sagar', date: '27 July • 02:30 PM', image: Propic, status: 'active' }, { title: 'Psychiatrist Consultation', doctor: 'Dr.Keerthi Sagar', date: '27 July • 02:30 PM', image: Propic, status: 'inactive' }, { title: 'Psychiatrist Consultation', doctor: 'Dr.Keerthi Sagar', date: '27 July • 02:30 PM', image: Propic, status: 'inactive' }] }]
    useEffect(() => {
        if (isNotEmpty(packages)) {
            setEmptyPackage(false)
        }
    }, [packages])
    function colorClass(color) {
        switch (color) {
            case 'active':
                return 'Style.active';
            case 'completed':
                return 'Style.completed';
            case 'inactive':
                return 'Style.inactive';

        }
    }
    console.log(colorClass('active'))
    return (
        <div>
            {
                emptyPackage === true ? <div>
                    <Typography>
                        No Packages Found
                    </Typography>
                    <Card className={Style.mainCardNopack}>
                        <Typography>
                            No Packages Found
                        </Typography>
                    </Card>
                </div> : <div>{
                    packages.map((data) => (
                        <>
                            <Typography>
                                {data.name}
                            </Typography>
                            <Card className={Style.mainCardNopack}>
                                {
                                    (isNotEmpty(data.session)) === true ? <Card style={{
                                        border: "1px dashed #3A84D7",
                                        borderRadius: "20px"
                                    }}><Typography>
                                           <span></span> Book your first session
                                        </Typography>
                                    </Card> : <div style={{
                                        display: 'flex',
                                        width: '96%',
                                        padding: '2%'

                                    }}>
                                        {data?.session?.map((value) => (
                                            < div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                margin: '1%',
                                                width: '25%',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <Card className={[Style.invidiualCard, value.status === "completed" ? Style.completed : value.status === "active" ? Style.active : Style.inactive]} >
                                                    <Typography className={Style.textTitle}>
                                                        {value.title}
                                                    </Typography>
                                                    <div style={{
                                                        display: 'flex',
                                                        padding: '10%',
                                                        width: '80%',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'

                                                    }}>
                                                        <img className={Style.imgPropic} src={value.image} />
                                                        <Typography className={Style.textDoctorName}>
                                                            {value.doctor}
                                                        </Typography>
                                                    </div>
                                                    <Typography className={Style.textDate}>
                                                        {value.date}
                                                    </Typography>
                                                </Card>
                                                <Button className={value.status === "completed" ? Style.buttonCompleted : value.status === "active" ? Style.buttonActive : Style.buttonInactive}>
                                                    {value.status === "completed" ? <>
                                                        Completed
                                                    </> : "Schedule Now"}
                                                </Button>

                                            </div>
                                        ))
                                        }
                                    </div>
                                }
                                <Typography>

                                </Typography>
                            </Card>
                        </>
                    ))}
                </div>
            }
        </div >
    )
}

export default ManagePackage