import { Typography, TextField, Button, FormControlLabel } from '@mui/material'
import React, { useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLocation, useNavigate } from 'react-router-dom';


const SignUpQuestionTwo = () => {
    const [value, setValue] = useState(null);
    const location = useLocation()
    const isSelf = location.pathname.indexOf("myself") > -1;
    const Navigate = useNavigate()
    const handleSubmit = () => {
        if (isSelf === true) {
            Navigate('/auth/signupquestion/myself/three')
        }
        else {
            Navigate('/auth/signupquestion/someone/three')

        }
    }
    return (
        < div style={{
            width: '70%',
            padding: '5% 15%'
        }}>
            <Typography style={{
                //styleName: H2;
                width: '50%',
                padding: '0 25%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                fontFamily: "PP Telegraf",
                fontSize: "39px",
                fontWeight: "600",
                lineHeight: " 47px",
                letterSpacing: "-0.02em",
                textAlign: "center"

            }}>Tell us a little bit
                about yourself?
            </Typography>
            <div style={{
                width: '50%',
                padding: '2% 25%'
            }}>
                <Typography style={{
                    fontFamily: "Satoshi",
                    fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textASlign: "left"

                }}>
                    Date Of Birth
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} style={{
                    width: '100%',
                    borderRadius: '25px'
                }}>

                    <DatePicker
                        fullWidth
                        // label="Basic example"
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                </LocalizationProvider>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '10%'
                }}>
                    <Button style={{
                        fontFamily: "Inter",
                        fontSize: "15px",
                        fontWeight: "600",
                        lineHeight: "24px",
                        background: '#FFF',
                        letterSpacing: "0.3799999952316284px",
                        textAlign: "center",
                        border: '1px solid #475569',
                        color: "#475569",
                        margin: '0 2%'

                    }}>
                        Clear
                    </Button>
                    <Button style={{
                        fontFamily: "Inter",
                        fontSize: "15px",
                        fontWeight: "600",
                        lineHeight: "24px",
                        background: '#FFF',
                        letterSpacing: "0.3799999952316284px",
                        textAlign: "center",
                        border: '1px solid #FFF',
                        backgroundColor: "#3B82F6",
                        color: '#FFF'

                    }} onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </div>
        </div >
    )
}

export default SignUpQuestionTwo