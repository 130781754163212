import React from 'react';
import { Card } from '@mui/material'
import DuoIcon from '@mui/icons-material/Duo';
import Style from './css/StartAppoinment.module.scss'
import { BsCameraVideo } from "react-icons/bs";

import video from '../../../asserts/dashboard/start-appoinment.png'
const StartAppoinmentCard = () => {
    return (
        <Card className={Style.mainCard}>
            <div className={Style.containerDiv}>
                <img src={video} className={Style.duoIcon} /> 
                {/* <DuoIcon className={Style.duoIcon} /> */}
                {/* <BsCameraVideo color='#fff' /> */}

                <div className={Style.textStart}>
                    Start Appointment

                </div>
            </div>

        </Card>

    )
}

export default StartAppoinmentCard