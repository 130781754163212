import { Typography, FormControl, InputLabel, Button, InputAdornment, Input, TextField, Box, OutlinedInput, FormHelperText } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import OTPInput, { ResendOTP } from "otp-input-react";
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Style from './css/Login.module.scss'
const Login = () => {
    const Navigate = useNavigate()
    const [isMobileNumberError, setMobileNumberError] = useState()
    const [mobileNumber, setMobileNumber] = useState("")
    const [OTP, setOTP] = useState();
    const [otpError, setOtpError] = useState(false);
    const [otpView, setOtpView] = useState(false)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(59)
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);
    const handleOTPSubmit = () => {
        if (OTP === "0000") {
            setOTP()
            setOtpError(true);
        }
        else {
            Navigate('/auth/login/signup')
            setOtpView(false)
        }
        // }
        // else {
        //     Navigate('/auth/signupquestion/one')
        //     setOtpView(false)
        // }
    }
    return (
        <div className={Style.mainDiv}>

            <Typography className={Style.textHaveAccount}>
                Don’t have an account?<span className={Style.buttonSignUp} onClick={() => Navigate('/auth/login/signup')}> Sign Up
                </span>
            </Typography>
            <div className={Style.margin}>
                <Typography className={Style.textBegin}>
                    Begin Your Journey
                </Typography>
                <Typography className={Style.textLogin}>
                    Login with your
                    mobile number
                </Typography>
                <Box className={Style.box}>
                    <InputLabel htmlFor="input-with-icon-adornment" className={Style.fontSathoshi}>
                        Mobile Number
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>

                        <OutlinedInput className={Style.outlinedInput}
                            value={mobileNumber}
                            error={isMobileNumberError ? true : false}
                            inputMode='numeric'
                            maxLength="2"
                            type="number"
                            inputProps={{ maxLength: 2 }}
                            id="input-with-icon-adornment"
                            startAdornment={
                                <InputAdornment position="start">
                                    🇮🇳
                                </InputAdornment>
                            }
                            onInput={(e) => {
                                setMobileNumber(e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10))
                            }} placeholder="Enter Mobile Number"
                        />
                        {
                            isMobileNumberError ? <FormHelperText>
                                {isMobileNumberError}
                            </FormHelperText> : ""
                        }
                    </FormControl>
                    {
                        otpView === true ?
                            <div style={{
                                padding: "10px"
                            }} >
                                <InputLabel htmlFor="input-with-icon-adornment" className={Style.fontSathoshi}>
                                    OTP
                                </InputLabel>
                                <OTPInput style={{
                                    padding: '20px 50px'
                                }} inputStyles={{
                                    border: 'none', borderBottom: '1px solid black'
                                }} value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} secure />
                                {
                                    otpError === true ?
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <Button onClick={() => { setOtpView(false) }} className={Style.buttonResendOtp}>Resend OTP</Button>
                                            <Typography>
                                                {seconds === 0 && minutes === 0 ? null : ` Auto sending OTP in  ${minutes} : ${seconds}`}
                                            </Typography>

                                        </div>
                                        : null
                                }
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Button onClick={() => { handleOTPSubmit() }} className={[Style.buttonSubmit, OTP?.length === 4 ? Style.buttonSubmitYes : Style.buttonSubmitNo,
                                    ]}>Submit</Button>
                                </div>
                            </ div> :
                            <Button onClick={() => { setOtpView(true) }} disabled={mobileNumber?.length === 10 ? false : true} className={[Style.buttonGetOtp, mobileNumber?.length === 10 ? Style.buttonGetOtpYes : Style.buttonGetOtpNo]}>Get OTP</Button>
                    }
                </Box>
            </div>
        </div >

    )
}

export default Login