import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Login from './pages/Auth/Login/Login';


import IndexRoutes from './router/router';


function App() {
  const routes = IndexRoutes.map((route) => {
    if (route.subRoutes?.length > 0) {
      return (
        <Route key={route.path} path={route.path} element={<route.element />}>
          {route.subRoutes.map((subRoute) => (
            <Route
              key={subRoute.path}
              path={subRoute.path}
              element={<subRoute.element prop />}
            />
          ))}

        </Route>
      );
    }
    return (
      <Route key={route.path} path={route.path} element={<route.element />} />
    );
  });

  return (
    <Routes>
      {routes}
      <Route path='/' element={<Navigate to='/auth/login' />} />
    </Routes>
    // <LandingPage />
  );
}

export default App;
