import { Card } from '@mui/material'
import React from 'react'
import Style from './css/NextAppoinment.module.scss';
import imgage from '../../../asserts/appoinment/Rectangle_163.png'
const NextAppoinment = () => {
  return (
    <Card className={Style.mainCard}>
      Next Appoinment
      <img width="100%" src={imgage} className={Style.imgDiv} />

      {/* <img scr={imgage}  /> */}
    </Card>
  )
}

export default NextAppoinment