import React, { useState, useEffect } from 'react'
import { Controller, useForm } from "react-hook-form";
import { isNotEmpty } from '../../../helpers/objectHelper';
import { Box, InputLabel, FormControl, OutlinedInput, Button, FormHelperText, TextField, Typography } from '@mui/material';

const SignUpForm = ({ setView }) => {
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("")
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        if (data) {
            setView(false);
        }
        else {
            console.log(errors, "errors")
        }
    }
    // console.log(data, "data")
    // console.log(errors, "error");
    useEffect(() => {
        let defaultValues = {};
        defaultValues.firstName = "Kristof";
        defaultValues.lastName = "Rado";
        reset({ ...defaultValues });
    }, [])

    return (

        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
            <Typography style={{
                fontWeight: "600",
                fontSize: "19px",
                lineHeight: "23px",
                /* identical to box height, or 121% */
                fontFamily: 'PP Telegraf',

                textAlign: "center",

                /* Black */

                color: "#090A09",
            }}>
                Begin your journey
            </Typography>
            <Typography style={{
                fontWeight: "600",
                fontSize: "39px",
                lineHeight: "47px",
                /* identical to box height, or 121% */
                fontFamily: 'PP Telegraf',

                textAlign: "center",
                letterSpacing: "-0.02em",

                /* Black */

                color: "#090A09"
            }}>
                Enter your details
            </Typography>
            <form style={{
                padding: '2% 30%',
                alignContent: 'center',
            }} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name={"firstName"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel htmlFor="input-with-icon-adornment" style={{
                                fontFamily: 'Satoshi'

                            }}>
                                First Name
                            </InputLabel>
                            <FormControl variant="outlined" fullWidth>

                                <OutlinedInput style={{
                                    borderRadius: '25px', margin: " 15px 0", backgroundColor: '#FFF'

                                }}
                                    onChange={onChange}
                                    placeholder="Enter First Name"
                                    value={value}
                                    //  error={firstNameError?.length > 0 ? true : false}
                                    type="text"
                                    id="input-with-icon-adornment"
                                    {...register("firstName", { required: true, maxLength: 80 })}

                                />
                                {isNotEmpty(errors) === true ? null :
                                    errors && errors?.firstName ? <FormHelperText>
                                        {errors?.firstName?.type}
                                    </FormHelperText> : null

                                }
                            </FormControl>
                        </>

                        // <OutlinedInput onChange={onChange} value={value} style={{
                        //     borderRadius: '10px !important'
                        // }} label={"Text Value"} />
                    )}
                />
                <Controller
                    name={"lastName"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel htmlFor="input-with-icon-adornment">
                                Last Name
                            </InputLabel>
                            <FormControl variant="outlined" fullWidth>

                                <OutlinedInput style={{
                                    borderRadius: '25px', margin: " 15px 0", backgroundColor: '#FFF'
                                }}
                                    placeholder="Enter Last Name"

                                    onChange={onChange}
                                    value={value} error={lastNameError?.length > 0 ? true : false}
                                    type="text"
                                    id="input-with-icon-adornment"
                                    {...register("lastName", { required: true, maxLength: 80 })}

                                />
                                {
                                    firstNameError?.length > 0 ? <FormHelperText>
                                        {firstNameError}
                                    </FormHelperText> : ""
                                }
                            </FormControl>
                        </>

                        // <OutlinedInput onChange={onChange} value={value} style={{
                        //     borderRadius: '10px !important'
                        // }} label={"Text Value"} />
                    )}
                />
                <Controller
                    name={"email"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel htmlFor="input-with-icon-adornment">
                                Last Name
                            </InputLabel>
                            <FormControl variant="outlined" fullWidth>

                                <OutlinedInput style={{
                                    borderRadius: '25px', margin: " 15px 0", backgroundColor: '#FFF'
                                }}
                                    placeholder="Email"
                                    {...register("email", { required: true, maxLength: 80 })}

                                    onChange={onChange}
                                    value={value} error={emailError?.length > 0 ? true : false}
                                    type="email"
                                    id="input-with-icon-adornment"
                                />
                                {
                                    firstNameError?.length > 0 ? <FormHelperText>
                                        {firstNameError}
                                    </FormHelperText> : ""
                                }
                            </FormControl>
                        </>

                        // <OutlinedInput onChange={onChange} value={value} style={{
                        //     borderRadius: '10px !important'
                        // }} label={"Text Value"} />
                    )}
                />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Button style={{
                        background: "#3A84D7",
                        alignItems: "flex-start",
                        padding: "10px 57px",
                        gap: "10px",
                        borderRadius: '20px',
                        color: '#fff'
                    }} onClick={handleSubmit(onSubmit)}>Next</Button>
                    {/* <Button onClick={() => reset()} variant={"outlined"}>Reset</Button> */}
                </div>

            </form>
        </Box >
    )

}
export default SignUpForm