import { Container, Typography, Card } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import Style from './AppoinmentDashboard.module.scss'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import UpcommingAppoinmentIndividual from '../../../components/Dashboard/card/UpcommingAppoinmentIndividual';
import AppoinmentUpcommingDashboard from '../../../components/Appoinment/AppoinmentDashBoard/AppoinmentUpcommingDashboard';
import NextAppoinment from '../../../components/Appoinment/AppoinmentDashBoard/NextAppoinment';
import AppBars from '../../../components/Dashboard/AppBar/AppBar';
import SupportCard from '../../../components/Dashboard2/card/SupportCard';

const AppoinmentDashnoard = () => {

    return (
        <>
            <AppBars />
            <Container style={{
                backgroundColoe: "#F5F5F5",
                padding: '2%',
                width: '98%'
            }}>
                <div>
                    <div style={{
                        display: 'flex',
                        // margin: "140px 50px 30px 230px"

                    }}>
                        <Card className={Style.cardAppoinment} >
                            <div style={{
                                margin: "48px 53px",
                                display: 'flex',
                                justifyContent: 'space-between'

                            }}>
                                <Typography className={Style.textBookAppoinment}>
                                    Book An Appoinment
                                </Typography>
                                <Typography style={{
                                    border: '3px solid black',
                                    borderRadius: '20px',
                                    padding: '10px',
                                    textAlign: 'center'
                                }}>
                                    <AddIcon style={{
                                        fontSize: '3rem'

                                    }} />
                                </Typography>
                            </div>
                        </Card>
                        <Card className={Style.cardBookPackage}>

                            <div style={{
                                margin: '22px'
                            }}>
                                <Typography className={Style.textBookPackage}>
                                    Book An Package
                                </Typography>
                                <Typography style={{
                                    border: '1px solid black',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '4px',
                                    textAlign: 'center',
                                    width: '30px'
                                }}>
                                    <AddIcon />
                                </Typography>
                            </div>
                        </Card>
                        <Card className={Style.cardBookPackage}>
                            <div style={{
                                margin: '22px'
                            }}>
                                <Typography className={Style.textBookPackage}>
                                    Book a follow up

                                </Typography>
                                <Typography style={{
                                    border: '1px solid black',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '4px',
                                    textAlign: 'center',
                                    width: '30px'
                                }}>
                                    <AddIcon />
                                </Typography>
                            </div>
                        </Card>
                    </div>
                    <div style={{
                        display: 'flex',
                        padding: '20px 0'
                    }}>
                        <div>
                            <Card className={Style.cardAppoinment} >
                                <div style={{
                                    margin: "48px 53px",
                                    display: 'flex',
                                    justifyContent: 'space-between'

                                }}>
                                    <Typography className={Style.textBookAppoinment}>
                                        Manage Packages                                </Typography>
                                    <Typography style={{
                                        border: '3px solid black',
                                        borderRadius: '20px',
                                        padding: '10px',
                                        textAlign: 'center'
                                    }}>
                                        <ArrowOutwardIcon style={{
                                            fontSize: '3rem'

                                        }} />
                                    </Typography>
                                </div>
                            </Card>
                            <AppoinmentUpcommingDashboard list="" />
                        </div>
                        <div>
                            <NextAppoinment />
                            <SupportCard />
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default AppoinmentDashnoard