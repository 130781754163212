import { Typography, ToggleButtonGroup, Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


const SignUpQuestionFour = () => {
    const [answers, setAnswers] = useState([])
    const [temp, setTemp] = useState([])
    const [invert, setInvert] = useState(false)
    const Navigate = useNavigate()
    const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
        '&.Mui-selected, &.Mui-selected:hover': {
            color: '#FFF',
            backgroundColor: selectedColor,
        },
    }));
    const handleAnswers = (event, newFormats) => {
        console.log(answers, "answer")

        if (event.target.value == "no") {

            handleInvert()
        }
        else {
            setAnswers(newFormats);
        }
    };
    const Options = [{
        label: "Specific Learning Disability",
        value: "specificLearningDisability",
        color: "#5DC48D"
    }, {
        label: "Drug Addiction",
        value: "drugAddiction",
        color: "#5DC48D"

    }, {
        label: "Obsessive Compulsive Disorder",
        value: "obsessiveCompulsiveDisorder",
        color: "#5DC48D"

    }, {
        label: "Schizophrenia",
        value: "schizophrenia",
        color: "#5DC48D"

    }, {
        label: "Personality Disorders",
        value: "personalityDisorders",
        color: "#5DC48D"

    }, {
        label: "Dementia",
        value: "dementia",
        color: "#5DC48D"

    }, {
        label: "Depression",
        color: "#5DC48D",
        value: "depression",
    }, {
        label: "Bipolar Disorder",
        value: "bipolarDisorder",
        color: "#5DC48D"

    }, {
        label: "Alcohol Addiction",
        value: "alcoholAddiction",
        color: "#5DC48D"

    }, {
        label: "Alzheimer’s",
        value: "alzheimer",
        color: "#5DC48D"

    }, {
        label: "Anxiety",
        value: 'anxiety',
        color: "#5DC48D"

    }, {
        label: "Relationship Issues",
        value: "relationshipIssues",
        color: "#5DC48D"

    }, {
        label: "Child mental disorders",
        value: "childMentalDisorders",
        color: "#5DC48D"

    }]
    const handleInvert = () => {
        setAnswers([]);

        if (invert === false) {
            setTemp(answers);
            setAnswers(['no'])
            setInvert(true)
        }
        else {
            setAnswers(temp);
            setTemp([]);
            setInvert(false)
        }

    }
    const handleSubmit = () => {
        Navigate('/auth/signupquestion/someone/five')
    }
    return (
        <div style={{
            width: '80%',
            padding: '0 10%'
        }}>
            <Typography style={{
                width: "30%",
                padding: '5% 35%',
                height: "94px",
                fontFamily: 'PP Telegraf',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "39px",
                lineHeight: "47px",
                textAlign: "center",
                letterSpacing: "-0.02em",

                /* Black */

                color: "#090A09"


            }}>
                What do you
                need assistance with?
            </Typography>
            <Grid container spacing={2}>

                <ToggleButtonGroup
                    value={answers}
                    // exclusive={invert === true ? true : false}
                    onChange={handleAnswers}
                    aria-label="Platform"
                    style={{
                        width: '90vw',
                        flexWrap: 'wrap'
                    }}
                    // orientation='vertical'
                    size='large'
                >

                    {
                        Options.map((option, index) => (

                            <ToggleButton value={option.value} selectedColor="#5DC48D" aria-label="bold" key={option.value} disabled={invert} style={{
                                display: "flex",
                                alignItems: "flex-start",

                                padding: " 24px 36px",
                                margin: '20px 5px',
                                // overflow: 'scroll',
                                gap: "10px",
                                // height: "70px",
                                // width: "283px",
                                fontFamily: 'Satoshi',
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "18px",
                                lineHeight: "22px",
                                // color: "#090A09",
                                // background: "#FFFFFF",
                                border: "1.56587px solid #D2D6DC",
                                boxShadow: "0px 1.56587px 3.13174px rgba(0, 0, 0, 0.05)",
                                borderRadius: "10px",
                                wordWrap: 'normal'
                            }} classes={{ selected: "buttonColor" }}
                            >
                                {/* <Grid item xs="auto"> */}
                                {/* <span>
            <CheckCircleIcon />
        </span>  */}
                                {option.label}
                                {/* </Grid> */}

                            </ToggleButton>

                        ))
                    }
                    <ToggleButton value="no" selectedColor="#E36048" aria-label="bold" style={{
                        display: "flex",
                        alignItems: "flex-start",

                        padding: " 24px 36px",
                        margin: '20px 5px',
                        // overflow: 'scroll',
                        gap: "10px",
                        // height: "70px",
                        // width: "283px",
                        fontFamily: 'Satoshi',
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "22px",
                        // color: "#090A09",
                        // background: "#FFFFFF",
                        border: "1.56587px solid #D2D6DC",
                        boxShadow: "0px 1.56587px 3.13174px rgba(0, 0, 0, 0.05)",
                        borderRadius: "10px",
                        wordWrap: 'normal'
                    }}
                        classes={{ selected: "buttonColor" }}
                    >
                        Im not sure

                    </ToggleButton>

                </ToggleButtonGroup>
            </Grid>

            <div style={{
                display: "flex",
                justifyContent: 'center',
            }}>
                <Button style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: " 16px 57px",
                    gap: "10px", justifyContent: 'center',
                    color: '#FFF',

                    width: "149px",
                    height: "51px",
                    background: "#5DC48D",
                    borderRadius: "100px",
                }} onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
        </div >
    )
}

export default SignUpQuestionFour