import { Box, InputLabel, FormControl, Checkbox, Select, MenuItem, OutlinedInput, Button, FormHelperText, TextField, Typography, ListItemText } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { isNotEmpty } from '../../../helpers/objectHelper';

const SignUpSomeoneForm = () => {
    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();
    const Navigate = useNavigate()
    const onSubmit = (data) => {
        if (data) {
            // setView(false);
            Navigate('/auth/signupquestion/someone/two')
        }
        else {
            console.log(errors, "errors")
        }
    }
    const onError = (errors) => {
        console.log(errors, "error")
    }
    return (
        <div>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                <Typography variant='h2' style={{
                    //styleName: H2;
                    fontFamily: "PP Telegraf",

                    fontSize: "39px",
                    fontWeight: "600",
                    lineHeight: "47px",
                    letterSpacing: "-0.02em",
                    textAlign: "center",
                    width: '30%',
                    padding: '0 35%'

                }}>
                    Tell us a little bit
                    about your loved one?
                </Typography>
                <form style={{
                    padding: '2% 30%',
                    alignContent: 'center',
                }} onSubmit={handleSubmit(onSubmit, onError)}>
                    <Controller
                        name={"firstName"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <InputLabel htmlFor="input-with-icon-adornment">
                                    Patient's First Name
                                </InputLabel>
                                <FormControl variant="outlined" fullWidth>

                                    <OutlinedInput style={{
                                        borderRadius: '25px', margin: " 15px 0", backgroundColor: '#FFF'

                                    }}
                                        onChange={onChange}
                                        placeholder="Enter First Name"
                                        value={value}
                                        //  error={firstNameError?.length > 0 ? true : false}
                                        type="text"
                                        id="input-with-icon-adornment"
                                        {...register("pateintFirstName", { required: true, maxLength: 80 })}

                                    />
                                    {isNotEmpty(errors) === true ? null :
                                        errors && errors?.firstName ? <FormHelperText>
                                            {errors?.firstName?.type}
                                        </FormHelperText> : null

                                    }
                                </FormControl>
                            </>

                            // <OutlinedInput onChange={onChange} value={value} style={{
                            //     borderRadius: '10px !important'
                            // }} label={"Text Value"} />
                        )}
                    />
                    <Controller
                        name={"lastName"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <InputLabel htmlFor="input-with-icon-adornment">
                                    Patient's Last Name
                                </InputLabel>
                                <FormControl variant="outlined" fullWidth>

                                    <OutlinedInput style={{
                                        borderRadius: '25px', margin: " 15px 0", backgroundColor: '#FFF'
                                    }}
                                        placeholder="Enter Last Name"

                                        onChange={onChange}
                                        value={value} error={false}
                                        type="text"
                                        id="input-with-icon-adornment"
                                        {...register("pateintlLstName", { required: true, maxLength: 80 })}

                                    />
                                </FormControl>
                            </>

                            // <OutlinedInput onChange={onChange} value={value} style={{
                            //     borderRadius: '10px !important'
                            // }} label={"Text Value"} />
                        )}
                    />
                    <Controller
                        name={"relatioship"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <InputLabel htmlFor="input-with-icon-adornment">
                                    Patient's Relationship
                                </InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        // multiple
                                        onChange={onChange}
                                        value={value}
                                        {...register("patientRelation")}
                                    // onChange={handleChange}
                                    // input={<OutlinedInput label="Name" />}
                                    // MenuProps={MenuProps}
                                    >
                                        {/* <MenuItem value="parent">
                                            <Checkbox checked={value?.indexOf("parent") > -1} />
                                            <ListItemText primary="parent" />
                                        </MenuItem> */}
                                        <MenuItem value="parent">Parent</MenuItem>

                                        <MenuItem value="siblings">Siblings</MenuItem>


                                    </Select>

                                </FormControl>
                            </>

                            // <OutlinedInput onChange={onChange} value={value} style={{
                            //     borderRadius: '10px !important'
                            // }} label={"Text Value"} />
                        )}
                    />
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Button style={{
                            background: "#3A84D7",
                            alignItems: "flex-start",
                            padding: "10px 57px",
                            margin: '20px',
                            gap: "10px",
                            borderRadius: '20px',
                            color: '#fff'
                        }} onClick={handleSubmit(onSubmit, onError)}>Next</Button>
                        {/* <Button onClick={() => reset()} variant={"outlined"}>Reset</Button> */}
                    </div>

                </form>
            </Box >
        </div >
    )
}

export default SignUpSomeoneForm