import React from 'react'
import { Card, Typography } from '@mui/material'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
const QuoteCarousal = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      // slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      // slidesToSlide: 1 // optional, default to 1.
    }
  };

  const CustomDot = ({ onMove, index, onClick, active }) => {
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <li
        className={active ? "active" : "inactive"}
        style={{
          border: "none",
          marginTop: "3rem"

        }}
        onClick={() => onClick()}
      >
        <FiberManualRecordIcon sx={{ color: active ? "#c7c7c7" : "#fff", fontSize: '0.75rem' }} />

      </li>
    );
  };
  const jsonOutput = [{
    author: "Aron",
    message: "“Quote of the week, this updates every week”"
  }, {
    author: "Aron",
    message: "“Quote of the week, this updates every week”"
  }, {
    author: "Aron",
    message: "“Quote of the week, this updates every week”"
  }, {
    author: "Aron",
    message: "“Quote of the week, this updates every week”"
  }]

  return (
    <Card style={{
      height: "179px",
      borderRadius: '20px', backgroundColor: "#3a84d7",
      boxShadow: " 0px 1.57px 3.13px rgba(0, 0, 0, 0.05)",

      margin: '0'
      // boxShadow: '0px 1.57px 3.13px rgba(0, 0, 0, 0.05)'

    }}>
      <Carousel
        style={{
          backgroundColor: "#3a84d7",
          height: '100%',
          padding: '0% 0',
          width: '96%'

        }}
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        // means to render carousel on server-side.
        infinite={false}
        customDot={<CustomDot />}
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
      // itemClass="carousel-item-padding-40-px"
      >
        {
          jsonOutput.map((data, i) => (
            <div key={i} style={{
              borderRadius: "10px",
              color: '#FFF',
              // height: '92%',
              padding: '6% 0',
              // textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              // alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: "#3a84d7",
border:'0px'

            }}>
              <Typography style={{
                // width: '60%',
                // padding: '2% 20%',
                // fontWeight: '600',
                // fontSize: '1rem',
                // fontFamily: "Satoshi, sans-serif",
                // textAlign: 'center',
                // justifyContent: 'center',
                // display: 'flex',
                // letterSpacing: '0.035em'
                fontSize: "1.25rem",
                fontWeight: "700",
                lineHeight: "1.25",
                letterSpacing: "0.05em",
                textAlign: "center",
                padding: "10px 15%", width: " 70%",
                fontFamily: '"Satoshi", sans-serif',
                color: " #fff",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'


              }}>
                {data.message}
              </Typography>
              <Typography style={{
                fontFamily: "Satoshi, sans-serif",
                textAlign: 'center',
                justifyContent: 'center',
                display: 'flex',
                fontWeight: '400',
                fontSize: "1rem",

              }}>
                {data.author}
              </Typography>
            </div>

          ))
        }
      </Carousel>
    </Card>
  )
}

export default QuoteCarousal