import { Container, Typography } from '@mui/material'
import React from 'react'
import LoginImg from '../../asserts/login/Cadabams Logo White 2.png'
import Style from './LoginStaticBar.module.scss'
const LoginStaticBar = () => {
    return (
        <Container className={Style.mainContainer}>
            <div className={Style.divmain}>
                <img src={LoginImg} />
                <Typography className={Style.textWelcome}>
                    Welcome Back
                </Typography>
                <Typography className={Style.normalText}>
                    to the single portal for all your mental health needs.
                </Typography>

            </div>
        </Container>
    )
}

export default LoginStaticBar