import { Grid, Typography, Card, Container } from '@mui/material'
import React from 'react'
import moment from 'moment';
import Style from './css/AppoinmentCard.module.scss'
import { isNotEmpty } from '../../../helpers/objectHelper';
import { useState } from 'react';
import line from '../../../asserts/dashboard/Vector_169.png'

const AppoinmentCard = ({ upcomming }) => {
    const [appoinment, setAppoinment] = useState(true);
    console.log(upcomming)
    if (isNotEmpty(upcomming)) {
        setAppoinment(true)
    }

    let dateformat1 = new Date(upcomming?.start_date)
    dateformat1.setHours(dateformat1.getHours() + 5)
    dateformat1.setMinutes(dateformat1.getMinutes() + 30)
    let dateformat = moment(dateformat1).format('MMMM DD yyyy')
    let dataformat11 = dateformat.split(" ")

    const setDay = (date) => {
        let day = moment(date).day();
        console.log(dataformat11, "day")

        switch (day) {
            case 0:
                return "Sunday";
                break;
            case 1:
                return "Monday";
                break;
            case 2:
                return "Tuesday";
                break;
            case 3:
                return "Wednesday";
                break;
            case 4:
                return "Thursday";
                break;
            case 5:
                return "Friday";
                break;
            case 6:
                return "saturday";
                break;
        }
    }
    const days = setDay(upcomming?.start_date);

    console.log(days, "format")
    const mon = dataformat11[0];
    const date = dataformat11[1];
    const year = dataformat11[2]

    return (
        <div className={Style.mainCard}>
            <div style={{
                padding: '1.5rem'
            }}>
                {appoinment === true ?

                    <Grid container item xs={12} md={12} lg={12} sm={12}  >
                        <div>
                            <Typography className={Style.textNext}>
                                Next Appointment
                            </Typography>
                        </div>
                        <Grid item container xs={12} sm={12} md={12} lg={12} >
                            <Grid item xs={4} sm={4} md={4} lg={4} >
                                <Typography className={Style.textHeaddate} >
                                    {/* {date !== "date" ? date : 22} */}
                                    22
                                </Typography>
                                <Typography className={Style.textHeadday}>
                                    {/* {mon},&nbsp;{year} */}
                                    August, 2022
                                </Typography>

                                <Typography className={Style.textHeaddayNor} >
                                    {/* {days} */}
                                    Thursday

                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} lg={4} md={4} style={{
                                display: 'flex',
                                // alignItems: 'center',
                                margin: '5% 0',
                                justifyContent: 'center'
                            }}>

                                <img src={line} height='4%' width="70%"
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} >

                                <Typography className={Style.textTime}>
                                    {/* {moment(dateformat1).format("HH:mm")} */}
                                    16:30
                                </Typography>
                                <Typography className={Style.textAppoinmentWithnorm}>
                                    {/* with {upcomming?.doctor_name} */}
                                    with Dr.Justin Sudhar
                                </Typography>
                                <Typography className={Style.textAppoinmentWith}>

                                    Online                                    </Typography>
                            </Grid>
                        </Grid>

                    </Grid> : <Grid container item xs={12} md={12}>
                        <div style={{
                            padding: '31px 0'
                        }}>
                            <Typography style={{
                                fontFamily: "'Satoshi', sans-serif",
                                width: '60%',
                                fontSize: "18px",
                                fontWeight: "700",
                                lineHeight: "22px",
                                letterSpacing: "0em",
                                textAlign: "left"

                            }}>
                                No Upcoming Appointments
                            </Typography>
                            <Typography style={{
                                //styleName: H4;
                                fontFamily: "PP Telegraf",
                                fontSize: "27px",
                                fontWeight: "600",
                                padding: '15px 0',
                                lineHeight: "32px",
                                letterSpacing: "0em",
                                textAlign: "left"

                            }}>
                                Book an Appointment Now
                            </Typography>
                        </div>
                    </Grid>}
            </div>
        </div >
    )
}

export default AppoinmentCard