import React, { useState, useEffect } from "react";
import Style from "./DoctorListing.module.scss";
import AppBars from "../../../../components/Dashboard/AppBar/AppBar";
import {
  Button,
  Card,
  Container,
  Grid,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import doctorImg from "../../../../asserts/doctors/doctor.png";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

const DoctorListing = () => {
  const [answers, setAnswers] = useState();
  const [outputArray, setOutputArray] = useState();
  const [final, setFinal] = useState();

  const list = [
    {
      type: "Clinical Psychologist",
      doctors: [
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
      ],
    },

    {
      type: "Psychiatrists",
      doctors: [
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
      ],
    },
    {
      type: "Family Therapists",
      doctors: [
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
        {
          image: doctorImg,
          name: "Dr. Priya Raghavan",
          qualification: "MBBS, MRCP (UK), CCT",
          price: "1000",
        },
      ],
    },
  ];
  const Options = [
    {
      label: "All",
      value: "all",
      color: "#5DC48D",
    },
    {
      label: "Clinical Psychologist",
      value: "clinicalPsychologist",
      color: "#5DC48D",
    },
    {
      label: "Psychiatrist",
      value: "psychiatrist",
      color: "#5DC48D",
    },
    {
      label: "Family Therapists",
      value: "familyTherapists",
      color: "#5DC48D",
    },
  ];
  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "#FFF",
      backgroundColor: selectedColor,
    },
  }));
  useEffect(() => {
    setOutputArray(list);
    setFinal(list);
  }, []);
  const handleChangelToggle = (e) => {
    setAnswers(e.target.value);
    if (e.target.value === "clinicalPsychologist") {
      setFinal([outputArray[0]]);
    } else if (e.target.value === "psychiatrist") {
      setFinal([outputArray[1]]);
    } else if (e.target.value === "familyTherapists") {
      setFinal([outputArray[2]]);
    } else {
      setFinal(outputArray);
    }
  };
  const handleColor = (e) => {
    if (e === "clinicalPsychologist") {
      console.log(e, "e");
      return "Style.backGreen";
    }
  };
  return (
    <div>
      <AppBars />
      <Container>
        <div
          style={{
            width: "80%",
            padding: "0 10%",
          }}
        >
          {" "}
          <ToggleButtonGroup
            value={answers}
            exclusive={true}
            onChange={(e) => handleChangelToggle(e)}
            aria-label="Platform"
            style={{
              width: "90vw",
              flexWrap: "wrap",
            }}
            // orientation='vertical'
            size="large"
          >
            {Options.map((option, index) => (
              <ToggleButton
                value={option.value}
                selectedColor="#5DC48D"
                aria-label="bold"
                key={option.value}
                //   disabled={invert}
                style={{
                  display: "flex",
                  alignItems: "flex-start",

                  // padding: " 24px 36px",
                  margin: "20px 5px",
                  // overflow: 'scroll',
                  gap: "10px",
                  // height: "70px",
                  // width: "283px",
                  fontFamily: "Satoshi",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "18px",
                  lineHeight: "22px",
                  // color: "#090A09",
                  // background: "#FFFFFF",
                  border: "1.56587px solid #D2D6DC",
                  boxShadow: "0px 1.56587px 3.13174px rgba(0, 0, 0, 0.05)",
                  borderRadius: "10px",
                  wordWrap: "normal",
                }}
                classes={{ selected: "buttonColor" }}
              >
                {/* <Grid item xs="auto"> */}
                {/* <span>
            <CheckCircleIcon />
        </span>  */}
                {option.label}
                {/* </Grid> */}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
        {final?.map((data, i) => (
          <div>
            <Typography className={Style.textType}>{data.type}</Typography>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Grid container spacing={2}>
                {data.doctors.map((val, indec) => {
                  return (
                    <Grid item xs={12} sm={12} lg={4} md={4} spacing={3}>
                      <div
                        className={
                          // Style.imageContainers,
                          handleColor(data.type)
                        }
                      >
                        <img src={val.image} />
                      </div>
                      <div
                        style={{
                          width: "80%",
                          margin: "0 10%",
                          textAlign: "center",
                        }}
                      >
                        <Typography>{val.name}</Typography>
                        <Typography>{val.qualification}</Typography>
                        <Button className={Style.buttonBook}>
                          INR {val.price} | Book Now
                        </Button>
                      </div>
                      {/* </Card> */}
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            {/* <Card>
                
            </Card> */}
          </div>
        ))}
      </Container>
    </div>
  );
};

export default DoctorListing;
