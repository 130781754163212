import React from 'react'
import AppoinmentCalender from '../Calender/AppoinmentCalender'
import UpcommingAppoinmentIndividual from './UpcommingAppoinmentIndividual'
import Style from './css/UpcommingAppoinment.module.scss'
const UpcommingAppoinment = ({ list }) => {
  return (
    <div className={Style.mainDiv}>
      <AppoinmentCalender />
      <UpcommingAppoinmentIndividual list={list} style={{
       innerHeight: "45%"
      }} />
    </div>
  )
}

export default UpcommingAppoinment