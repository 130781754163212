import { Typography, Switch, Grid, Button } from '@mui/material'
import React, { useState } from 'react'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
const SignUpPermission = () => {
  const Navigate = useNavigate()
  const [location, setLocation] = useState(false)
  const [bluetooth, setBluetooth] = useState(false);
  const [tracking, setTracking] = useState(false)
  const handleChange = (event, name) => {
    if (name === "location") {
      setLocation(event.target.checked)
    }
    else if (name === "bluetooth")
      setBluetooth(event.target.checked);
    else if (name === "tracking")
      setTracking(event.target.checked)
  };
  const handleSubmit = () => {
    if (location === true && bluetooth === true && tracking === true) {
      Navigate('/auth/signupquestion/setup')
    }
  }
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#5DC48D',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  return (
    <div style={{
      width: '40%',
      padding: '5% 30%',

    }}><Typography style={{
      textAlign: 'center',
      fontFamily: 'PP Telegraf',
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "39px",
      lineHeight: "47px",
      /* identical to box height, or 121% */

      letterSpacing: "-0.02em",

      /* Black */

      color: "#090A09"
    }}>Permissions</Typography>
      <div style={{
        backgroundColor: '#FFFDF5',
        width: '70%',
        padding: '5% 15%'
      }}>
        <Grid container spacing={0}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '10px'

            // alignItems: 'center'
          }}>
            <Grid item xs={2}>

              <AntSwitch checked={location} onChange={(e) => handleChange(e, "location")} inputProps={{ 'aria-label': 'ant design' }} />
            </Grid>
            <Grid item xs={10}>

              <Typography style={{
                fontFamily: 'PP Telegraf',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "23px",
                lineHeight: "28px",
                /* identical to box height, or 122% */
                alignItems: 'center',
                display: "flex",
                alignItems: "center",
                letterSpacing: "-0.02em",

                /* Black */

                color: "#090A09"

              }}>Location</Typography>
              <Typography style={{
                fontFamily: 'Satoshi',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "19px",
                /* or 119% */

                display: "flex",
                alignItems: "center",

                color: "#757575"


              }}>
                Access to GPS and location services helps improve our services.               </Typography>
            </Grid>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '10px'
          }}>
            <Grid item xs={2}>

              <AntSwitch checked={bluetooth} onChange={(e) => handleChange(e, "bluetooth")} inputProps={{ 'aria-label': 'ant design' }} />
            </Grid>
            <Grid item xs={10}>
              <Typography style={{
                fontFamily: 'PP Telegraf',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "23px",
                lineHeight: "28px",
                /* identical to box height, or 122% */
                alignItems: 'center',
                display: "flex",
                alignItems: "center",
                letterSpacing: "-0.02em",

                /* Black */

                color: "#090A09"

              }}>                                Bluetooth
              </Typography>
              <Typography style={{
                fontFamily: 'Satoshi',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "19px",
                /* or 119% */

                display: "flex",
                alignItems: "center",

                color: "#757575"


              }}>
                Bluetooth permission helps the application work better.              </Typography>
            </Grid>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '10px'
          }}>
            <Grid item xs={2}>

              <AntSwitch checked={tracking} onChange={(e) => handleChange(e, "tracking")} inputProps={{ 'aria-label': 'ant design' }} />
            </Grid>
            <Grid item xs={10}>
              <Typography style={{
                fontFamily: 'PP Telegraf',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "23px",
                lineHeight: "28px",
                /* identical to box height, or 122% */
                alignItems: 'center',
                display: "flex",
                alignItems: "center",
                letterSpacing: "-0.02em",

                /* Black */

                color: "#090A09"

              }}>Allow Tracking
              </Typography>
              <Typography style={{
                fontFamily: 'Satoshi',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "19px",
                /* or 119% */

                display: "flex",
                alignItems: "center",

                color: "#757575"


              }}>
                Helps us offer more personalized services and a better user experience.               </Typography>
            </Grid>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '5% 0'

          }}>
            <Button style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              padding: "16px 57px",
              gap: "10px",
              width: "187px",
              height: "51px",
              background: "#5DC48D",
              borderRadius: "100px",
              color: '#FFF'
            }} onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Grid>
      </div >
    </div >
  )
}

export default SignUpPermission