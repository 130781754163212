import React from 'react'
import AppoinmentCalender from '../Calender/AppoinmentCalender'
import UpcommingAppoinmentIndividual from './UpcommingAppoinmentIndividual'
const UpcommingAppoinment = ({ list }) => {
  return (
    <div style={{
      height: '550px',
      background: '#FFF',
      padding: '1.5% 0',
      borderRadius: '20px'
    }}>
      <AppoinmentCalender />
      <UpcommingAppoinmentIndividual style={{
        width: '80%',
        margin: '0 10%'
      }} list={list} />
    </div>
  )
}

export default UpcommingAppoinment