import React from "react";

const DashBoardContainer = (props) => {
  return (
    <div
      style={{
        maxWidth: "1440px",
        maxHeight: "940px",
        margin: "auto",
        display: "block",
        // padding: "2%",
        // height: "90vh",
        // width: "85vw",
        // overflow: "hidden",
        // margin: "2.5% 3.5%",
      }}
    >
      {props.children}
    </div>
  );
};

export default DashBoardContainer;
