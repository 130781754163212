import { styled } from "@mui/material/styles";
import { Container, div, Paper, Typography, Grid } from "@mui/material";
import React, { useEffect } from "react";
import AppBars from "../../components/Dashboard/AppBar/AppBar";
import AppoinmentCard from "../../components/Dashboard/card/AppoinmentCard";
import StartAppoinmentCard from "../../components/Dashboard/card/StartAppoinmentCard";
import ManageAppoimnet from "../../components/Dashboard/card/ManageAppoimnet";
import ResourceCheckoutCard from "../../components/Dashboard/card/ResourceCheckoutCard";
import VideoCarousal from "../../components/Dashboard/Carousal/VideoCarousal";
import AddCarousal from "../../components/Dashboard/Carousal/AddCarousal";
import TotalAppoinmentCard from "../../components/Dashboard/card/TotalAppoinmentCard";
import SupportCard from "../../components/Dashboard/card/SupportCard";
import UpcommingAppoinment from "../../components/Dashboard/card/UpcommingAppoinment";
import QuoteCarousal from "../../components/Dashboard/Carousal/QuoteCarousal";
import { useState } from "react";
import axios from "axios";
import { API } from "../../constants/APICONFIG";
import DashBoardContainer from "../../components/Dashboard/Container/DashBoardContainer";

const DashBoard = () => {
  const [data, setData] = useState();
  useEffect(() => {
    let output = getDashboardJson();
    setData(output);
  }, []);
  console.log(data, "data value666");
  const getDashboardJson = () => {
    axios({
      method: "get",
      url: API.DASHBOARD,
      responseType: "json",
    })
      .then(function (response) {
        console.log(response?.data, "res");
        setData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <AppBars />
      <DashBoardContainer
        style={{
          display: "flex",
          //   flexDirection: "row",
            // padding: "20px",
          backgroundColor: "#F5F5F5",
        }}
      >
        <Grid container columnSpacing={2}>
          <Grid container item xs={12} md={5.4} lg={5.4} sm={12} spacing={2} columnSpacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              style={{
                height: "20vh",
              }}
            >
              <AppoinmentCard upcomming={data?.next_appointment} />
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              lg={6}
              sm={6}
              style={{
                height: "20vh",
              }}
            >
              <ManageAppoimnet />
            </Grid>{" "}
            <Grid
              item
              xs={6}
              md={6}
              lg={6}
              sm={6}
              style={{
                height: "20vh",
              }}
            >
              <ResourceCheckoutCard />
            </Grid>{" "}
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              style={{
                height: "35vh",
              }}
            >
              <AddCarousal />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={3} lg={3} sm={12} spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              style={{
                height: "20vh",
              }}
            >
              <StartAppoinmentCard />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              style={{
                height: "20vh",
              }}
            >
              <VideoCarousal/>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <TotalAppoinmentCard
                count={data?.total_appointment_counts}
                style={{
                  height: "20vh",
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              style={{
                height: "15vh",
              }}
            >
              <SupportCard />
            </Grid>
          </Grid>

          <Grid container item xs={12} md={3.5} lg={3.5} sm={12} spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              style={{
                height: "60vh",
              }}
            >
              <UpcommingAppoinment list={data?.upcoming_appointments} />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              style={{
                height: "30vh",
              }}
            >
              <QuoteCarousal />
            </Grid>
            {/* <Grid item xs={12} md={12}>
                            <Item>xs=4</Item>
                        </Grid> */}
          </Grid>
        </Grid>
      </DashBoardContainer>
    </>
  );
};

export default DashBoard;
