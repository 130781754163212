import React, { useState, useEffect } from 'react'
import { Typography, FormControl, InputLabel, Button, InputAdornment, Input, TextField, Box, OutlinedInput, FormHelperText } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import OTPInput, { ResendOTP } from "otp-input-react";

const SignUpMobile = () => {
    const [isMobileNumberError, setMobileNumberError] = useState()
    const [mobileNumber, setMobileNumber] = useState("")
    const [OTP, setOTP] = useState();
    const [otpError, setOtpError] = useState(false);
    const [otpView, setOtpView] = useState(false)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(59);
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
            <Typography style={{
                //styleName: H6;

                fontWeight: "600",
                fontSize: "19px",
                lineHeight: "23px",
                /* identical to box height, or 121% */

                textAlign: "center",

                /* Black */

                color: "#090A09",
            }}>
                Almost Done....
            </Typography>
            <Typography style={{
                // width: '50%',
                padding: '0 35%',
                fontWeight: "600",
                fontSize: "39px",
                lineHeight: "47px",
                /* identical to box height, or 121% */

                textAlign: "center",
                letterSpacing: "-0.02em",

                /* Black */

                color: "#090A09"
            }}>
                Verify your
                phone number            </Typography>
            <div style={{
                padding: '4% 30%',
                alignContent: 'center',
            }}>
                <InputLabel htmlFor="input-with-icon-adornment">
                    Mobile Number
                </InputLabel>
                <FormControl variant="outlined" fullWidth>

                    <OutlinedInput style={{
                        borderRadius: '25px'
                    }}
                        value={mobileNumber}
                        error={isMobileNumberError ? true : false}
                        inputMode='numeric'
                        maxLength="2"
                        type="number"
                        inputProps={{ maxLength: 2 }}
                        id="input-with-icon-adornment"
                        startAdornment={
                            <InputAdornment position="start">
                                {/* <AccountCircle /> */}
                                🇮🇳
                            </InputAdornment>
                        }
                        onInput={(e) => {
                            setMobileNumber(e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10))
                        }} placeholder="Enter Mobile Number"
                    />
                    {
                        isMobileNumberError ? <FormHelperText>
                            {isMobileNumberError}
                        </FormHelperText> : ""
                    }
                </FormControl>
                {
                    otpView === true ?
                        <>

                            <div style={{
                                padding: '20px 0'
                            }}>
                                <InputLabel htmlFor="input-with-icon-adornment">
                                    OTP
                                </InputLabel>

                                <OTPInput style={{
                                    padding: '0 50px'
                                }} inputStyles={{
                                    border: 'none', borderBottom: '1px solid black', color: otpError === true ? "#E36048" : "#5DC48D"
                                }} value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} secure />
                                {
                                    otpError === true ?
                                        <>Invalid OTP</> : null
                                }
                                {otpError === true ?
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        flexDirection: 'column'

                                    }}><Button onClick={() => { setOtpView(false) }} style={{
                                        padding: "10px 57px",
                                        margin: '10px',
                                        width: '70%',
                                        background: " #E36048",
                                        borderRadius: "100px",
                                        color: 'white'
                                    }}>Resend OTP</Button>
                                        <Typography>
                                            {seconds === 0 && minutes === 0 ? null : <span style={{
                                                color: '#5DC48D'
                                            }}> Auto sending OTP in <span>{minutes} : {seconds}</span></span>}
                                        </Typography>
                                    </div>

                                    : null
                                }
                                <Button onClick={() => { setOtpView(false) }} style={{
                                    padding: "10px 57px",
                                    margin: '10px',
                                    width: '50%',
                                    background: " #5DC48D",
                                    borderRadius: "100px",
                                    color: 'white'
                                }}>Submit</Button>
                            </ div>
                        </> :

                        <Button onClick={() => { setOtpView(true) }} disabled={mobileNumber?.length === 10 ? false : true} style={{
                            padding: "10px 57px",
                            margin: '10px',
                            width: '50%',
                            background: `#5DC48D`,
                            borderRadius: "100px",
                            color: 'white'
                        }}>Get OTP</Button>
                }
            </div>
        </Box>
    )
}
export default SignUpMobile