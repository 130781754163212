import React from 'react'
import { Card } from '@mui/material'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import Style from './css/SupportCard.module.scss'
const SupportCard = () => {
  return (
    <Card className={Style.mainCard}>
      <div style={{
        fontSize: "1rem",
        fontWeight: 'bolder'
      }}>SupportCard</div>
      <CallOutlinedIcon style={{
        border: '1px solid #042447',
        padding: '10px',
        borderRadius: '10px'
      }} />
      <MessageOutlinedIcon style={{
        border: '1px solid #042447',
        padding: '10px',
        borderRadius: '10px'
      }} />
    </Card>
  )
}

export default SupportCard