import React from 'react'
import { Card, Typography, Button } from '@mui/material'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Style from './css/AddCarousal.module.scss';
import carImage from '../../../asserts/dashboard/image_14.png';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const AddCarousal = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      // slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      // slidesToSlide: 1 // optional, default to 1.
    }
  };
  const jsonOutput = [{
    title: "How to deal with depression",
    src: carImage
  }, {
    title: "How to deal with depression",
    src: carImage
  }, {
    title: "How to deal with depression",
    src: carImage
  }, {
    title: "How to deal with depression",
    src: carImage
  }]
  const CustomDot = ({ onMove, index, onClick, active }) => {
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <li
        className={active ? "active" : "inactive"}
        style={{
          border: "none",
          marginTop: "5rem"

        }}
        onClick={() => onClick()}
      >
        <FiberManualRecordIcon sx={{ color: active ? "#98ACA9" : "#D9D9D9;", fontSize: '1rem' }} />

      </li>
    );
  };

  return (
    <Card className={Style.mainDiv}>
      <div style={{
        // padding: '2%'
      }}>

        <Carousel
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          // means to render carousel on server-side.
          infinite={false}
          // autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={1000}
          keyBoardControl={false}
          customDot={<CustomDot />}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          // deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          style=
          {{
            height: '98%'
          }}
        // itemClass="carousel-item-padding-40-px"
        >
          {
            jsonOutput.map((data, i) => (
              <Card key={i} style={{
                width: '100%',
                boxShadow: "none",
                // height: '90%'

                margin: '0 0 2% 0'
              }}>

                <img  className={Style.image} src={data.src} />

                <div className={Style.imageDiv}>
                  <div className={Style.titleText}>
                    {data.title}
                  </div>
                  <div>
                    <Button className={Style.buttonReady}>
                      <Typography className={Style.buttonText}>
                        Read Now
                      </Typography>

                    </Button>
                  </div>
                </div>
              </Card>

            ))
          }


        </Carousel >
      </div>
    </Card >
  )
}

export default AddCarousal