import React from 'react'
import { Card } from '@mui/material'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import Style from './css/SupportCard.module.scss'
import call from '../../../asserts/dashboard/Union.png'
import message from '../../../asserts/dashboard/Message_circle.png'
const SupportCard = () => {
  return (
    <Card className={Style.mainCard}>
      {/* <div style={{
        padding: '1rem 2rem 1rem 0'
      }}> */}
        <div className={Style.textSupport}>Support </div>
        <img src={call} className={Style.iconCall} />
        <img src={message} className={Style.iconMessage} />

      {/* </div> */}
      {/* <CallOutlinedIcon className={Style.iconCall} /> */}
      {/* <MessageOutlinedIcon style={{
        border: '1px solid #042447',
        padding: '10px',
        borderRadius: '10px'
      }} /> */}
    </Card>
  )
}

export default SupportCard