import React from 'react'
import { Container, Grid } from '@mui/material'
import { Outlet } from 'react-router-dom'
import SignUpNavbar from '../components/SignUp/SignUpNavbar'

const SignUpLayout = () => {
    return (
        <div style={{
            width: '100%'
        }}>
            <SignUpNavbar />
            <Outlet />
        </div>
    )
}

export default SignUpLayout