import { Button, Container, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import SplashCarousal from '../../components/splash/SplashCarousal'
import SplashNavbar from '../../components/splash/SplashNavbar'
import Style from './Splash.module.scss'
const Splash = () => {
    const Navigate = useNavigate()
    const svg = <svg width="589" height="320" viewBox="0 0 589 320" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.1" filter="url(#filter0_d_464_3624)">
            <path d="M569.263 0H20.2013C10.5747 0 2.77075 8.75082 2.77075 19.5455V295.626C2.77075 306.421 10.5747 315.171 20.2013 315.171H569.263C578.889 315.171 586.693 306.421 586.693 295.626V19.5455C586.693 8.75082 578.889 0 569.263 0Z" fill="#EEEEEE" />
        </g>
        <defs>
            <filter id="filter0_d_464_3624" x="0.657825" y="0" width="588.148" height="319.397" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="2.11293" />
                <feGaussianBlur stdDeviation="1.05646" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_464_3624" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_464_3624" result="shape" />
            </filter>
        </defs>
    </svg>

    return (
        <>
            <SplashNavbar />
            <Container className={Style.mainContainer}>
                <Typography className={Style.textJourney}>
                    Your journey to better mental
                    health is just a click away
                </Typography>
                <SplashCarousal />
                <div className={Style.buttonContainer}>
                    <Button className={Style.buttonLogin} onClick={() => { Navigate('/auth/login') }}>
                        Login
                    </Button>
                    <Button className={Style.buttonSignUp}>
                        SignUp
                    </Button>
                </div>
                <div className={Style.bottomContainer}>
                    <Typography >
                        Having issues?
                    </Typography>
                    <Typography>
                        Talk to the support team

                    </Typography>
                </div>
            </Container>
        </>
    )
}

export default Splash