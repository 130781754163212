import { Typography } from '@mui/material';
import React, { useState } from 'react'
import LoginSignUpformone from './LoginSignUpformone';
import LoginSignUpformtwo from './LoginSignUpformtwo';
import Style from './css/LoginSignUp.module.scss';
const LoginSIgnUp = () => {
    const [formView, setFormView] = useState(true);
    // const []
    return (
        <div className={Style.mainDiv}>
            <Typography className={Style.textOops}>
                Oops, No Account Found
            </Typography>
            <Typography className={Style.textSignUp}>
                Sign Up
                Now!
            </Typography>
            <div className={Style.form}>
                {
                    formView === true ? <LoginSignUpformone view={formView} setView={setFormView} /> : <LoginSignUpformtwo view={formView} setView={setFormView} />
                }
            </div>
        </div>
        // <div>LoginSIgnUp</div>
    )
}

export default LoginSIgnUp