import React from 'react'
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import { Card, Typography } from '@mui/material';
import arrow from '../../../asserts/dashboard/Vector.png'
import Style from './css/ManageAppoinment.module.scss'
const ManageAppoimnet = () => {
  return (
    <Card className={Style.mainCard}>
      <div style={{
        padding: '1rem 2rem'
      }}>
        <Typography className={Style.textManage}>Manage Appointments

        </Typography>
        <div className={Style.divIcon}>
          <img src={arrow} className={Style.icon} />
          {/* <ArrowOutwardOutlinedIcon className={Style.icon} /> */}

        </div>
      </div>
    </Card>
  )
}

export default ManageAppoimnet