import React, { useState, useEffect } from 'react'
import { Typography, FormControl, InputLabel, Button, InputAdornment, Input, TextField, Box, OutlinedInput, FormHelperText } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import OTPInput, { ResendOTP } from "otp-input-react";
import Style from './css/LoginSignUpformtwo.module.scss'
import { useNavigate } from 'react-router-dom';
const LoginSignUpformtwo = () => {
    const [isMobileNumberError, setMobileNumberError] = useState()
    const [mobileNumber, setMobileNumber] = useState("")
    const [OTP, setOTP] = useState();
    const Navigate = useNavigate()
    const [otpError, setOtpError] = useState(false);
    const [otpView, setOtpView] = useState(false)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(59);
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);
    const handleSubmit = () => {
        if (OTP === "0000") {
            setOTP();
            setOtpError(true);
        }
        else {
            Navigate('/auth/login')
            setOtpView(false);
        }

    }
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
            <InputLabel htmlFor="input-with-icon-adornment" className={Style.fontSathoshi}>
                Mobile Number
            </InputLabel>
            <FormControl variant="outlined" fullWidth>

                <OutlinedInput className={Style.outlinedInput}
                    value={mobileNumber}
                    error={isMobileNumberError ? true : false}
                    inputMode='numeric'
                    maxLength="2"
                    type="number"
                    inputProps={{ maxLength: 2 }}
                    id="input-with-icon-adornment"
                    startAdornment={
                        <InputAdornment position="start">
                            🇮🇳
                        </InputAdornment>
                    }
                    onInput={(e) => {
                        setMobileNumber(e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10))
                    }} placeholder="Enter Mobile Number"
                />
                {
                    isMobileNumberError ? <FormHelperText>
                        {isMobileNumberError}
                    </FormHelperText> : ""
                }
            </FormControl>

            {otpView === true ?
                <>
                    <InputLabel htmlFor="input-with-icon-adornment" className={Style.fontSathoshi}>
                        OTP
                    </InputLabel>
                    <div style={{
                        margin: '20px 50px'
                    }}>

                        <OTPInput inputStyles={{
                            border: 'none', borderBottom: '1px solid black'
                        }} value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} secure />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {otpError === true ?

                                <>
                                    <Button onClick={() => { setOtpView(false) }} className={Style.buttonResendOtp}>Resend OTP</Button>
                                    <Typography>
                                        {seconds === 0 && minutes === 0 ? null : ` Auto sending OTP in  ${minutes} : ${seconds}`}
                                    </Typography></> : null}
                            < Button onClick={() => { handleSubmit() }} className={Style.buttonSubmit}>Submit</Button>

                        </div>
                    </ div>
                </>
                : null
            }


            {
                otpView === false ?

                    <Button onClick={() => { setOtpView(true) }} disabled={mobileNumber?.length === 10 ? false : true} className={[Style.buttonGetOtp, mobileNumber?.length === 10 ? Style.buttonGetOtpYes : Style.buttonGetOtpNo]}>Get OTP</Button> :
                    null
            }
        </Box >
    )
}


export default LoginSignUpformtwo