import { Typography, InputLabel, FormControl, FormControlLabel, InputAdornment, FormHelperText, OutlinedInput, Button } from '@mui/material'
import React, { useState } from 'react'
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const SignUpQuestionThree = () => {
    const [aadhar, setAadhar] = useState();
    const Navigate = useNavigate();
    const location = useLocation()
    const [aadharError, setAadharError] = useState();
    const [isAadharVerified, SetAadharVerified] = useState(false)
    const handleSubmit = () => {
        if (isAadharVerified) {

            Navigate('/auth/signupquestion/myself/four')
        }
        else {
            Navigate('/auth/signupquestion/someone/four')
        }
    }
    return (
        <div>

            <Typography style={{
                fontFamily: "PP Telegraf",
                fontSize: "39px",
                fontWeight: "600",
                lineHeight: "47px",
                letterSpacing: "-0.02em",
                textAlign: "center",
                padding: '2% 0'

            }}>
                Verify your Aadhar</Typography>
            <div style={{
                width: '40%',
                padding: '0 30%'
            }}>

                <InputLabel htmlFor="input-with-icon-adornment">
                    Aadhar Number
                </InputLabel>
                <FormControl variant="outlined" fullWidth>
                    <div style={{
                        display: 'flex',
                        padding: '10px'
                    }}>
                        <OutlinedInput style={{
                            borderRadius: '25px',
                            width: '85%'
                        }}
                            value={aadhar}
                            error={aadharError ? true : false}
                            inputMode='numeric'
                            maxLength="2"
                            type="number"
                            inputProps={{ maxLength: 2 }}
                            id="input-with-icon-adornment"
                            // startAdornment={
                            //     <InputAdornment position=">start">
                            //         <AccountCircle />
                            //     </InputAdornment>
                            // }
                            onInput={(e) => {
                                setAadhar(e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12));
                            }} placeholder="Enter Aadhar Number"
                        />
                        <Button startIcon={isAadharVerified === true ? <CheckCircleIcon /> : ""} style={{
                            width: '15%',
                            fontFamily: 'Satoshi',
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "14.4772px",
                            lineHeight: "18px",
                            color: isAadharVerified === true ? "#5DC48D" : "#000"
                        }}>
                            Verify</Button>
                    </div>
                    {
                        aadharError ? <FormHelperText>
                            {aadharError}
                        </FormHelperText> : ""
                    }
                </FormControl>

                <div style={{
                    display: "flex",
                    justifyContent: 'center',

                }}>
                    <Button onClick={handleSubmit} style={{
                        flexDirection: "row",
                        alignItems: "flex-start",
                        padding: "16px 57px",
                        gap: "10px",
                        justifyContent: 'center',
                        width: "149px",
                        height: "51px",
                        color: '#fff',
                        background: "#5DC48D",
                        borderRadius: "100px",
                    }}>
                        Next
                    </Button>
                </div>

            </div >
        </div >
    )
}

export default SignUpQuestionThree