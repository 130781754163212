import { Container, Grid } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import LoginStaticBar from '../components/Login/LoginStaticBar'

const LoginLayout = () => {
    return (
        <div style={{
            width: '100%'
        }}>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <LoginStaticBar />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Outlet />
                </Grid>
            </Grid>
        </div>
    )
}

export default LoginLayout