import { styled } from "@mui/material/styles";
import { Container, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AppBars from "../../components/Dashboard2/AppBar/AppBar";
import AppoinmentCard from "../../components/Dashboard2/card/AppoinmentCard";
import StartAppoinmentCard from "../../components/Dashboard2/card/StartAppoinmentCard";
import ManageAppoimnet from "../../components/Dashboard2/card/ManageAppoimnet";
import ResourceCheckoutCard from "../../components/Dashboard2/card/ResourceCheckoutCard";
import VideoCarousal from "../../components/Dashboard2/Carousal/VideoCarousal";
import AddCarousal from "../../components/Dashboard2/Carousal/AddCarousal";
import TotalAppoinmentCard from "../../components/Dashboard2/card/TotalAppoinmentCard";
import SupportCard from "../../components/Dashboard2/card/SupportCard";
import UpcommingAppoinment from "../../components/Dashboard2/card/UpcommingAppoinment";
import QuoteCarousal from "../../components/Dashboard2/Carousal/QuoteCarousal";
import { useState } from "react";
import axios from "axios";
import { API } from "../../constants/APICONFIG";
import DashBoardContainer from "../../components/Dashboard/Container/DashBoardContainer";

const DashBoardBackup = () => {
  const [data, setData] = useState();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  useEffect(() => {
    let output = getDashboardJson();
    setData(output);
  }, []);
  console.log(data, "data value666");
  const getDashboardJson = () => {
    axios({
      method: "get",
      url: API.DASHBOARD,
      responseType: "json",
    })
      .then(function (response) {
        console.log(response?.data, "res");
        setData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div
      style={{
        // backgroundColor: '#c7c7c7',
        background: "#F5F5F5",
      }}
    >
      <AppBars />
      <DashBoardContainer>
        <div
          style={{
            // // padding: "20px 7.6%",
            // display: "block",
            margin: "2%",
            padding:' 0 5%'
            // margin: "auto",
          }}
        >
          <Grid container spacing={2}>
            <Grid container item xs={12} md={8.5} lg={8.5} sm={12} spacing={2}>
              <Grid item xs={12} md={8} lg={8} sm={12}>
                <AppoinmentCard upcomming={data?.next_appointment} />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={12}>
                <StartAppoinmentCard />
              </Grid>
              <Grid item xs={6} md={4} lg={4} sm={6}>
                <ManageAppoimnet />
              </Grid>{" "}
              <Grid item xs={6} md={4} lg={4} sm={6}>
                <ResourceCheckoutCard />
              </Grid>{" "}
              <Grid item xs={12} md={4} lg={4} sm={12}>
                <VideoCarousal />
              </Grid>
              <Grid item xs={12} md={8} lg={8} sm={12}>
                <AddCarousal />
              </Grid>
              <Grid item conatiner xs={12} md={4} lg={4} sm={12} spacing={2}>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <TotalAppoinmentCard count={data?.total_appointment_counts} />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <SupportCard />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={3.5} lg={3.5} sm={12} spacing={2}>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <UpcommingAppoinment list={data?.upcoming_appointments} />
              </Grid>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <QuoteCarousal />
              </Grid>
              {/* <Grid item xs={12} md={12}>
                            <Item>xs=4</Item>
                        </Grid> */}
            </Grid>
          </Grid>
        </div>
      </DashBoardContainer>
    </div>
  );
};

export default DashBoardBackup;
