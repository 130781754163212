import React from 'react'
import { Card } from '@mui/material'
import Style from './css/Total.module.scss'
const TotalAppoinmentCard = ({ count }) => {
  return (
    <Card className ={Style.mainCard}>
      <div style={{
        padding: '1rem 2rem'

      }}> <div className={Style.textTotal}>Total Appointments Completed
        </div>
        <div className={Style.textCount}>{count ? count : "0"}</div>

      </div>

    </Card>
  )
}

export default TotalAppoinmentCard