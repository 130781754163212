import { Typography, ToggleButtonGroup, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material'
import React from 'react'
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const SignUpQuestionOne = () => {
  const [value, setValue] = React.useState('web');
  const Navigate = useNavigate();
  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#FFF',
      backgroundColor: selectedColor,
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSubmit = () => {
    if (value === "yourself") {
      Navigate('/auth/signupquestion/myself/two')

    }
    else if (value === "someoneElse") {
      Navigate("/auth/signupquestion/someone/form")
    }
  }
  // const handleChange = (event) => {
  //   set>Value(event.target.value);
  // };
  console.log(value, "value")
  return (
    <div style={{
      width: '30%',
      padding: '10% 35%'
    }}>
      <Typography style={{
        //styleName: H2;
        fontFamily: "PP Telegraf",
        fontSize: "39px",
        fontWeight: "600",
        lineHeight: "47px",
        letterSpacing: "-0.02em",
        textAlign: "center",

      }}>
        Who are you seeking
        our services for?
      </Typography>
      <ToggleButtonGroup
        // color="#000"
        exclusive
        value={value}
        onChange={handleChange}
        aria-label="Platform"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10% 0',

          // background: "#000",
          // color: '#FFF'

        }}
      >
        <ToggleButton value="yourself" style={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: " 24px 36px",
          gap: "10px",
          margin: '0 5px',
          height: "70px",
          border: "1.56587px solid #D2D6DC",
          boxShadow: "0px 1.56587px 3.13174px rgba(0, 0, 0, 0.05)",
          borderRadius: "10px"
        }}
          classes={{ selected: "buttonColor" }}
          selectedColor="#5DC48D">Yourself</ToggleButton>
        <ToggleButton value="someoneElse" selectedColor="#5DC48D" style={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: " 24px 36px",
          gap: "10px",
          fontSize: '18px',
          height: "70px",
          border: "1.56587px solid #D2D6DC",
          boxShadow: "0px 1.56587px 3.13174px rgba(0, 0, 0, 0.05)",
          borderRadius: "10px"
        }}>SomeoneElse</ToggleButton>
      </ToggleButtonGroup>

      <Button onClick={handleSubmit} style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: '0 25%',

        padding: " 16px 57px",
        gap: "10px",
        justifyContent: 'center',
        color: '#fff',

        /* Green */

        background: "#5DC48D",
        borderRadius: "100px"
      }}>
        Submit
      </Button>
    </div >
  )
}

export default SignUpQuestionOne