import { Container } from '@mui/material'
import React, { useState } from 'react'
import SignUpForm from './SignUpForm'
import SignUpMobile from './SignUpMobile'
import Style from './css/SignUp.module.scss';
const SignUp = () => {
    const [formView, setFormView] = useState(true)
    return (
        <Container className={Style.container}>
            {
                formView === true ? <SignUpForm setView={setFormView} /> : <SignUpMobile setView={setFormView} />
            }

        </Container>
    )
}

export default SignUp