import React from 'react'
import { Card, Typography } from '@mui/material'
import Style from './css/TotalAppoinmentCard.module.scss'
const TotalAppoinmentCard = ({ count }) => {
  return (
    <Card className={Style.mainCard}>
      <Typography className={Style.textTotal}>Total Appoinment Completed
      </Typography>
      <Typography className={Style.textCount}>{count}</Typography>
    </Card>
  )
}

export default TotalAppoinmentCard