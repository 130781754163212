import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import Style from './DrawerContent.module.scss';
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoIcon from '@mui/icons-material/Info';
import TuneIcon from '@mui/icons-material/Tune';
import PersonIcon from '@mui/icons-material/Person';
import StarBorderIcon from '@mui/icons-material/StarBorder';
const DrawerContent = () => {
    const values = [
        { name: "Home", icon: <HomeIcon /> },
        {
            name:
                "Appointment"
            , icon: <CalendarMonthIcon />
        },
        { name: "Support", icon: <InfoIcon /> },
        { name: "Settings", icon: <TuneIcon /> },
        { name: "Profiles", icon: <PersonIcon /> },
        { name: "Referrals", icon: <StarBorderIcon /> }
    ]
    return (
        <div className={Style.mainDiv}>
            <List style={{
                padding: '5% 150px'
            }}>
                {values.map((value) => (
                    <ListItem >
                        <ListItemIcon style={{
                            color: '#FFF'
                        }}>{value.icon}</ListItemIcon>

                        {/* <ListItemButton> */}
                            <ListItemText>
                                <Typography className={Style.listText}>
                                    {value.name}
                                </Typography>
                            </ListItemText>
                    </ListItem>
                ))}
            </List>
            <Typography className={Style.textCadabams}>
                Cadabams v1.0
            </Typography>
        </div>
    )
}

export default DrawerContent