import React from 'react'
import { Card, Typography, Button } from '@mui/material'
import Carousel from 'react-multi-carousel';
const SplashCarousal = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            // slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            // slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            // slidesToSlide: 1 // optional, default to 1.
        }
    };
    const jsonOutput = [{
        title: "Better mental health for the entire family with multiple patient profiles. ",
    }, {
        title: "From past appointments to your upcoming ones. All of it in one place.",
    }, {
        title: "Blogs, Explainers, Webinars, and much more. The one app for everything mental health.",
    }]

    return (
        <Carousel
            swipeable={false}
            draggable={false}
            arrows={false}
            showDots={true}
            responsive={responsive}
            // means to render carousel on server-side.
            infinite={false}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={false}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
        // itemClass="carousel-item-padding-40-px"
        >
            {
                jsonOutput.map((data, i) => (
                    <Card key={i} style={{
                        width: '80%', padding: '25px 10%'
                        // margin: '0px 5%'
                    }}>


                        <div style={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: "space-between"
                        }}>
                            <Typography style={{
                                //styleName: P4;
                                fontFamily: "Satoshi, sans-serif",

                                fontSize: "23px",
                                fontWeight: "500",
                                lineHeight: "38px",
                                letterSpacing: "0px",
                                textAlign: "center"

                            }}>
                                {data.title}
                            </Typography>
                        </div>
                    </Card>

                ))
            }


        </Carousel>
    )
}

export default SplashCarousal