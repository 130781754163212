import { Card, Typography } from "@mui/material";
import React, { useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment/moment";

import Style from "./css/UpcommingAppoinmentndividual.module.scss";
import { isNotEmpty } from "../../../helpers/objectHelper";
const UpcommingAppoinmentIndividual = ({ list }) => {
  const [record, setRecord] = useState();
  if (isNotEmpty(list)) {
    let list1 =
      list &&
      list?.map((data) => {
        let startDate = new Date(data.start_date);
        startDate.setHours(startDate.getHours() + 5);
        startDate.setMinutes(startDate.getMinutes() + 30);
        let endDate = new Date(data.end_date);
        endDate.setHours(endDate.getHours() + 5);
        endDate.setMinutes(endDate.getMinutes() + 30);
        let startTime = moment(startDate).format("HH:mm");
        let endTime = moment(endDate).format("HH:mm");
        let startTimeF = moment(startDate).format("DD MMM YYYY");
        let endTimeF = moment(endDate).format("DD MMM YYYY");

        let output = {
          doctorName: data.doctor_name,
          startTime: startTime,
          endTime: endTime,
          startDate: startTimeF,
          endDate: endTimeF,
        };
        return output;
        // list2.push(output)
      });
    setRecord(list1);
  }

  return (
    <Card className={Style.mainCard}>
      <div className={Style.divHeader}>
        <Typography className={Style.fontBold}>Upcoming Appointment</Typography>
        <Typography>
          <CalendarMonthIcon />
        </Typography>
      </div>
      <div>
        {record && record.length > 0 > 0 ? (
          record?.map((data, i) => (
            <Card key={i} className={Style.individualCard}>
              <Typography className={Style.textAppointment}>
                Appointment With {data?.doctorName}
              </Typography>
              <Typography className={Style.textOnline}>Online</Typography>
              <div>
                {data.startDate === data.endDate
                  ? `${data.startDate} ${data.startTime}-${data.endTime} `
                  : `${data.startDate} ${data.startTime}-${data.startDate} ${data.endTime} `}
              </div>
            </Card>
          ))
        ) : (
          <>
            xsxjsqxnjqsxjsxnjxjxjxjqxnjxx xkj xqjxwqjxnwqx xjqxqxjnxqxnqxjwqnx
            xjwxqxjxjxqx qxjqbxwqxwquxwuxbxuwxuxx
          </>
        )}
      </div>
    </Card>
  );
};

export default UpcommingAppoinmentIndividual;
