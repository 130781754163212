import React from 'react'
import { Card, Typography } from '@mui/material'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Style from './css/VideoCarousal.module.scss';
import carImage from '../../../asserts/dashboard/img-carosal.png';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const VideoCarousal = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            // slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            // slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            // slidesToSlide: 1 // optional, default to 1.
        }
    };

    const CustomDot = ({ onMove, index, onClick, active }) => {
        // onMove means if dragging or swiping in progress.
        // active is provided by this lib for checking if the item is active or not.
        return (
            <li
                className={active ? "active" : "inactive"}
                style={{
                    border: "none",
                    // paddingTop: "5rem"

                }}
                onClick={() => onClick()}
            >
                <FiberManualRecordIcon sx={{ color: active ? "#98ACA9" : "#D9D9D9;", fontSize: '1rem' }} />

            </li>
        );
    };
    const jsonOutput = [{
        title: "How to deal with depression",
        src: carImage
    }, {
        title: "How to deal with depression",
        src: carImage
    }, {
        title: "How to deal with depression",
        src: carImage
    }]
    return (
        <Card className={Style.mainCard}>
            <Carousel
                style={{
                    height: '97%',
                    // padding: '4% 0',
                    // marginBottom: '9%',
                    width: '98%',

                }}
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                customDot={<CustomDot />}
                // means to render carousel on server-side.
                infinite={false}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
            // itemClass="carousel-item-padding-40-px"
            >
                {
                    jsonOutput.map((data, i) => (
                        <Card key={i} className={Style.imgCard}>
                            <img className={Style.depression } src={data.src} />
                        </Card>

                    ))
                }


            </Carousel>
        </Card>
    )
}

export default VideoCarousal