import React from 'react'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import { Card, Typography } from '@mui/material';
import Style from './css/ResourceCheckoutCard.module.scss'
import book from '../../../asserts/dashboard/book.png'
const ResourceCheckoutCard = () => {
  return (
    <Card className={Style.mainCard}>
      <div style={{
        padding: '1rem 2rem'
      }}>
        <Typography className={Style.divResources}>Check out
          our Resources
        </Typography>
        <div className={Style.divIcon}>
          {/* <AutoStoriesOutlinedIcon className={Style.icon} /> */}
          <img src={book} className={Style.icon} />
        </div>
      </div>

    </Card>

  )
}

export default ResourceCheckoutCard