import React from 'react'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import { Card, Typography } from '@mui/material';
import Style from './css/ResourceCheckoutCard.module.scss'
const ResourceCheckoutCard = () => {
  return (
    <Card className={Style.mainCard}>
      <Typography className={Style.divResources}>Check out Our Resources
      </Typography>
      <div className={Style.divIcon}>
        <AutoStoriesOutlinedIcon className={Style.icon} />

      </div>
    </Card>

  )
}

export default ResourceCheckoutCard