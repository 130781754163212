import { Box, InputLabel, FormControl, OutlinedInput, Button, FormHelperText, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { Controller, useForm } from "react-hook-form";
import { isNotEmpty } from '../../../helpers/objectHelper';
import Style from './css/LoginSignUpformone.module.scss'

const LoginSignUpformone = ({ setView }) => {
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("")
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        if (data) {
            setView(false);
        }
    }
    const onError = (errors, e) => console.log(errors, e);

    // console.log(data, "data")
    // console.log(errors, "error");
    useEffect(() => {
        let defaultValues = {};
        defaultValues.firstName = "Kristof";
        defaultValues.lastName = "Rado";
        reset({ ...defaultValues });
    }, [])

    return (
        <div>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Controller
                        name={"firstName"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <InputLabel htmlFor="input-with-icon-adornment" className={Style.fontSathoshi}>
                                    First Name
                                </InputLabel>
                                <FormControl variant="outlined" fullWidth>

                                    <OutlinedInput className={Style.outlinedInput}
                                        onChange={onChange}
                                        placeholder="Enter First Name"
                                        value={value}
                                        //  error={firstNameError?.length > 0 ? true : false}
                                        type="text"
                                        id="input-with-icon-adornment"
                                        {...register("firstName", { required: true, maxLength: 80 })}

                                    />
                                    {isNotEmpty(errors) === true ? null :
                                        errors && errors?.firstName ? <FormHelperText>
                                            {errors?.firstName?.type}
                                        </FormHelperText> : null

                                    }
                                </FormControl>
                            </>

                            // <OutlinedInput onChange={onChange} value={value} style={{
                            //     borderRadius: '10px !important'
                            // }} label={"Text Value"} />
                        )}
                    />
                    <Controller
                        name={"lastName"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <InputLabel htmlFor="input-with-icon-adornment" className={Style.fontSathoshi}>
                                    Last Name
                                </InputLabel>
                                <FormControl variant="outlined" fullWidth
                                >

                                    <OutlinedInput className={Style.outlinedInput}
                                        placeholder="Enter Last Name"

                                        onChange={onChange}
                                        value={value} error={lastNameError?.length > 0 ? true : false}
                                        type="text"
                                        id="input-with-icon-adornment"
                                        {...register("lastName", { required: true, maxLength: 80 })}

                                    />
                                    {
                                        firstNameError?.length > 0 ? <FormHelperText>
                                            {firstNameError}
                                        </FormHelperText> : ""
                                    }
                                </FormControl>
                            </>

                            // <OutlinedInput onChange={onChange} value={value} style={{
                            //     borderRadius: '10px !important'
                            // }} label={"Text Value"} />
                        )}
                    />
                    <Controller
                        name={"email"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <InputLabel htmlFor="input-with-icon-adornment" className={Style.fontSathoshi}>
                                    Email
                                </InputLabel>
                                <FormControl variant="outlined" fullWidth>

                                    <OutlinedInput className={Style.outlinedInput}
                                        placeholder="Email"
                                        {...register("email", { required: true, maxLength: 80 })}

                                        onChange={onChange}
                                        value={value} error={emailError?.length > 0 ? true : false}
                                        type="email"
                                        id="input-with-icon-adornment"
                                    />
                                    {
                                        firstNameError?.length > 0 ? <FormHelperText>
                                            {firstNameError}
                                        </FormHelperText> : ""
                                    }
                                </FormControl>
                            </>

                            // <OutlinedInput onChange={onChange} value={value} style={{
                            //     borderRadius: '10px !important'
                            // }} label={"Text Value"} />
                        )}
                    />
                    <div>
                        <Button className={Style.buttonSubmit} onClick={handleSubmit(onSubmit)}>Submit</Button>
                        {/* <Button onClick={() => reset()} variant={"outlined"}>Reset</Button> */}
                    </div>

                </form>
            </Box>
        </div>
    )

}

export default LoginSignUpformone