import { Grid, Typography, Card } from '@mui/material'
import React from 'react'
import moment from 'moment';
import Style from './css/AppoinmentCard.module.scss'

const AppoinmentCard = ({ upcomming }) => {
    console.log(upcomming)
    let dateformat1 = new Date(upcomming?.start_date)
    dateformat1.setHours(dateformat1.getHours() + 5)
    dateformat1.setMinutes(dateformat1.getMinutes() + 30)
    let dateformat = moment(dateformat1).format('MMMM DD yyyy')
    let dataformat11 = dateformat.split(" ")
    const setDay = (date) => {
        let day = moment(date).day();
        console.log(dataformat11, "day")

        switch (day) {
            case 0:
                return "Sunday";
                break;
            case 1:
                return "Monday";
                break;
            case 2:
                return "Tuesday";
                break;
            case 3:
                return "Wednesday";
                break;
            case 4:
                return "Thursday";
                break;
            case 5:
                return "Friday";
                break;
            case 6:
                return "saturday";
                break;
        }
    }
    const days = setDay(upcomming?.start_date);

    console.log(days, "format")
    const mon = dataformat11[0];
    const date = dataformat11[1];
    const year = dataformat11[2]
    return (
        <Card className={Style.mainCard}>
            <Grid container item xs={12} md={12} style={{
                // margin: '22px 0'
            }}>
                <div>
                    <Typography className={Style.textNext}>
                        Next Appointment
                    </Typography>
                </div>
                <Grid item xs={12} sm={12} md={12} lg={12} className={Style.gridItem}>
                    <div>
                        <Typography className={Style.textHeaddate} >
                            {/* {date ? date : 22} */}
                            22

                        </Typography>
                        <Typography className={Style.textHeadday}>
                            {/* {mon},&nbsp;{year} */}
                            August, 2022

                        </Typography>

                        <Typography className={Style.textHeaddayNor} >
                            {/* {days} */}
                            Thursday

                        </Typography>
                    </div>
                    <div>
                        <Typography className={Style.textTime}>
                            {/* {moment(dateformat1).format("HH:mm")}</Typography> */}
                            16:30
                        </Typography>

                        <Typography className={Style.textAppoinmentWithNor}>
                            {/* Appointment with {upcomming?.doctor_name} */}
                            with Dr. Xyz Placeholder

                        </Typography>
                        <Typography className={Style.textAppoinmentWith}>

                            Online                                    </Typography>
                    </div>
                </Grid>

            </Grid>
        </Card>
    )
}

export default AppoinmentCard