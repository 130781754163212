import { Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'

const SignUpPersonalise = () => {
    return (
        <Container>
            <Grid container spacing={2} style={
                {
                    margin: "10px",
                    minHeight: '80vh'
                }
            }>            <Grid item lg={6} md={6} sm={12} xs={12} style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '0 5%',
                // alignItems: 'center'
            }}>
                    <div style={{
                        width: '70%',

                    }}>
                        <Typography style={{

                            fontFamily: "PP Telegraf",
                            fontSize: "70px",
                            fontWeight: "600",
                            lineHeight: "84px",
                            letterSpacing: "-0.04em",
                            textAlign: "left",
                            paddingBottom: '20px'
                        }}>
                            Hello 👋
                            Lee Lee,
                        </Typography>
                    </div>
                    <Typography style={{
                        fontFamily: "Satoshi",
                        fontSize: "23px",
                        fontWeight: "500",
                        lineHeight: "38px",
                        letterSpacing: "0px",
                        textAlign: "left"
                    }}>
                        Your journey to better mental health has almost begun. A few more steps and you’ll be on your way!
                    </Typography>
                    <Button style={{
                        display: "flex",
                        marginTop: '20px',
                        flexDirection: "row",
                        alignItems: "flex-start",
                        padding: "16px 57px",
                        color: '#fff',
                        width: '50%',
                        background: "#F59E0B",
                        borderRadius: "100px"
                    }}>
                        Lets Start
                    </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    Image
                </Grid>
            </Grid>

        </Container >
    )
}

export default SignUpPersonalise